// src/contexts/DataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSocket } from './SocketContext';

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [playableCharacters, setPlayableCharacters] = useState(null);
  const [selectedChar, setSelectedChar] = useState(null);
  const [charEditMode, setCharEditMode] = useState(false);

  const socket = useSocket();

  useEffect(() => {
    if (socket == null) return;

    const fetchPlayableCharacters = async () => {
      try {
        const response = await fetch('/players');
        const data = await response.json();
        setPlayableCharacters(data);
      } catch (error) {
        console.error("Failed to fetch data: ", error);
      }
    };

    fetchPlayableCharacters();

    const handleEvent = (data) => {
      setPlayableCharacters(data);
    };

    socket.on('character_update', handleEvent);

    // Cleanup on component unmount
    return () => socket.off('character_update', handleEvent);
  }, [socket]);

  const makeSelection = (value) => {
    setSelectedChar(value);
  };

  return (
    <DataContext.Provider value={{ playableCharacters, selectedChar, charEditMode, makeSelection, setCharEditMode }}>
      {children}
    </DataContext.Provider>
  );
};