import React, { useState, useRef } from 'react';
import { IoVolumeMediumOutline } from "react-icons/io5";
import '../styles/AudioControl.css'; // Importing the CSS for styling
import { FaRegCirclePlay } from "react-icons/fa6";
import { CgPlayPauseO } from "react-icons/cg";



const AudioControl = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    // Handle play/pause toggle
    const togglePlayPause = () => {
        if (isPlaying) {
            //audioRef.current.pause();
            console.log("Stopping audio...")
        } else {
            //audioRef.current.play();
            console.log("Starting audio...")
        }
        setIsPlaying(!isPlaying);
    };

    return (
      <div className={`spinner ${isPlaying ? 'toggled' : ''}`} onClick={togglePlayPause}>
        <div className="spinner-content">
          <IoVolumeMediumOutline />
          
          {isPlaying ? <CgPlayPauseO /> : <FaRegCirclePlay />}
        </div>
      </div>
    );
};

export default AudioControl;
