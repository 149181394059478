import React, { useState, useRef } from 'react';
import { useData } from './DataContext';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import '../styles/CharacterForm.css';

const CharacterForm = ({ isEditMode, characterToEdit, cancel }) => {
  const { makeSelection, setCharEditMode } = useData();
  const defaultCharacterData = {
    name: '',
    maxHealth: '',
    class: '',
    race: '',
    level: '', // New field for level
    currentHealth: '', // New field for current health
    profileImage: null
  };

  const [characterData, setCharacterData] = useState(isEditMode ? characterToEdit : defaultCharacterData);
  const [imagePreview, setImagePreview] = useState(characterToEdit?.profileImage || '');
  const imageRef = useRef(null);

  const handleInputChange = (e) => {
    setCharacterData({ ...characterData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 72; // Set the width and height to 72px
          canvas.width = maxSize;
          canvas.height = maxSize;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, maxSize, maxSize);
          const resizedImage = canvas.toDataURL(file.type);
          setImagePreview(resizedImage); // Set the resized image as the preview
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setCharacterData({ ...characterData, profileImage: file });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', characterData.name);
    formData.append('maxHealth', characterData.maxHealth);
    formData.append('class', characterData.class);
    formData.append('race', characterData.race);
    formData.append('profileImage', characterData.profileImage);
    formData.append('level', characterData.level)
    formData.append('currentHealth', characterData.currentHealth)

    if (isEditMode) {
      try {
        formData.append('id', characterData.id)
        const response = await fetch('/updatecharacter', {
          method: 'PUT',
          body: formData, // FormData will correctly encode the file for upload
        });
  
        if (response.ok) {
          setCharEditMode(false);
          // Handle successful character creation (e.g., clearing the form or redirecting)
        } else {
          alert('Failed to create character');
          // Handle errors or unsuccessful character creation
        }
      } catch (error) {
        console.error('Error submitting form: ', error);
        alert('Error submitting the form');
      }
    } else {
      try {
        formData.append('id', Date.now());
        const response = await fetch('/newcharacter', {
          method: 'POST',
          body: formData, // FormData will correctly encode the file for upload
        });
  
        if (response.ok) {
          const data = await response.json(); // Wait for the JSON promise to resolve
          makeSelection(data.value);
          // Handle successful character creation (e.g., clearing the form or redirecting)
        } else {
          alert('Failed to create character');
          // Handle errors or unsuccessful character creation
        }
      } catch (error) {
        console.error('Error submitting form: ', error);
        alert('Error submitting the form');
      }
    }
  };

  return (
    <form className="character-form" onSubmit={handleSubmit}>
      <input type="file" ref={imageRef} onChange={handleFileChange} accept="image/*" style={{ display: 'none' }} />
      <div onClick={() => imageRef.current.click()} className="image-upload-placeholder">
        {imagePreview ? (
          <img src={imagePreview} alt="Preview" style={{ width: 72, height: 72, borderRadius: '50%' }} />
        ) : (
          <img src="/default_pfp.jpg" alt="Preview" style={{ width: 72, height: 72, borderRadius: '50%' }} />
        )}
      </div>
      <label>Name</label>
      <Input
        type="text"
        name="name"
        value={characterData.name}
        onChange={handleInputChange}
        placeholder="Name"
        required
      />
      {isEditMode && (
        <>
          <label>Level</label>
          <Input
            type="number"
            name="level"
            value={characterData.level}
            onChange={handleInputChange}
            required
          />
          <label>Current Health</label>
          <Input
            type="number"
            name="currentHealth"
            value={characterData.currentHealth}
            onChange={handleInputChange}
            required
          />
        </>
      )}
      <label>Health</label>
      <Input
        type="number"
        name="maxHealth"
        value={characterData.maxHealth}
        onChange={handleInputChange}
        placeholder="Max Health"
        required
      />
      <label>Class</label>
      <Input
        type="text"
        name="class"
        value={characterData.class}
        onChange={handleInputChange}
        placeholder="Class"
        required
      />
      <label>Race</label>
      <Input
        type="text"
        name="race"
        value={characterData.race}
        onChange={handleInputChange}
        placeholder="Race"
        required
      />
      <div className="button-group">
        <Button className ="form-btn" sx={{"background-color": "#ccc", "margin": "8px 8px 0 0"}} onClick={cancel}>Cancel</Button>
        <Button className ="form-btn" sx={{"background-color": "#4A4453", "margin-top": "8px"}}type="submit">{isEditMode ? 'Save' : 'Create'}</Button>
      </div>
    </form>
  );
};

export default CharacterForm;
