import React from 'react';

const RollEntry = ({ idx, roll }) => {
  const first_result = roll.first_roll.reduce((a, b) => a + b, 0) + roll.adjustment;
  const second_result = roll.second_roll.reduce((a, b) => a + b, 0) + roll.adjustment
  
  const first_string = `( ${roll.first_roll.slice(1).reduce((a,b) => a.concat(` + ${b.toString()}`), roll.first_roll[0].toString())}  )`;
  const second_string = `( ${roll.second_roll.slice(1).reduce((a,b) => a.concat(` + ${b.toString()}`), roll.second_roll[0].toString())}  )`;
  
  const adjustment_string = roll.adjustment < 0 ? ` - ${-1*roll.adjustment}` : roll.adjustment > 0 ? ` + ${roll.adjustment}` : '';
  
  if (roll.type === "basic") {
    return (
      <div style={idx===0 ? {"margin-top": "auto"} : {}} className="roll-entry">
        <div className="roll-title">
          {roll.user} rolled {first_result}.
        </div>
        <div className="roll-detail">
          {first_string}{adjustment_string}
        </div>
      </div>
    );
    } else if (roll.type === "advantage") {
      return (
        <div style={idx===0 ? {"margin-top": "auto"} : {}} className="roll-entry">
          <div className="roll-title">
            {roll.user} rolled {first_result > second_result ? first_result : second_result}.
          </div>
          <div className="roll-detail">
            {first_string}{adjustment_string}
          </div>
          <div className="roll-detail">
            {second_string}{adjustment_string}
          </div>
        </div>
      );
    } else {
      return (
        <div style={idx===0 ? {"margin-top": "auto"} : {}} className="roll-entry">
        <div className="roll-title">
          {roll.user} rolled {first_result < second_result ? first_result : second_result}.
        </div>
        <div className="roll-detail">
          {first_string}{adjustment_string}
        </div>
        <div className="roll-detail">
          {second_string}{adjustment_string}
        </div>
      </div>
      )
    }
};

export default RollEntry;
