import React from 'react';
import { useData } from './DataContext';
import '../styles/NamePlate.css'; // Assuming you will create a CSS file for styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const NamePlate = ({ name, level, charClass, profilePic, currentHealth, maxHealth, selected }) => {
  const { makeSelection, setCharEditMode } = useData();

  return (
    <div className="name-plate">
      <img src={profilePic ? profilePic : 'default_pfp.jpg'} alt={`${name}'s profile`} className="profile-pic"/>
      <div className="character-info">
        <h2 className="character-name">{name}</h2>
          {selected && (<div className="icon-container">
            <FontAwesomeIcon icon={faEdit} onClick={() => setCharEditMode(true)} />
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => makeSelection(null)} />
            </div>
          )}
        <div className="char-info">
          Lv. {level} {charClass}
        </div>
      </div>
      <div className="health-info">
        HP: {currentHealth} / {maxHealth}
      </div>
    </div>
  );
};

export default NamePlate;
