import React from 'react';
//import { marked } from 'marked';
import Markdown from 'markdown-to-jsx'
import { useData } from './DataContext';
import AudioControl from './AudioControl';

const Message = ({ text, human, sender }) => {
  const { playableCharacters } = useData();

  return (
    <div className="message">
      <div className="character-image">
        <img className="profile-pic" src={human ? playableCharacters.find((x) => x.id==sender).profileImage : "/tome.jpg"} />
      </div>
      <div className="message-text"><Markdown>{text}</Markdown></div>
    </div>)
  ;
};

export default Message;
