import { React, useState } from 'react';
import { useData } from './DataContext';
import CharacterForm from './CharacterForm';
import NamePlate from './NamePlate';
import Button from '@mui/joy/Button';
import '../styles/CharacterSelectionModal.css';

const SelectionModal = () => {
  const { playableCharacters, makeSelection } = useData();
  const [ createMode, setCreateMode ] = useState(false);

  const handleSelection = (value) => {
    makeSelection(value);
  };

  return createMode ? (<CharacterForm isEditMode={false} cancel={() => setCreateMode(false)}/>) : (
    <div className="modal-overlay">
      <div className="modal-content">
        {playableCharacters ? playableCharacters.map(char => (
            <div key={char.id} onClick={() => handleSelection(char.id)} >
              <NamePlate  name={char.name} level={char.level} charClass={char.class} profilePic={char.profileImage} currentHealth={char.currentHealth} maxHealth={char.maxHealth}/>
            </div>
        )) : '' }
        <Button className="new-char-btn" onClick={() => (setCreateMode(true))}>New Character</Button>
      </div>
    </div>
  );
};

export default SelectionModal;
