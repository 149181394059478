import React, { useState, useRef, useEffect } from 'react';
import RollEntry from './RollEntry';
import { useSocket } from './SocketContext';
import { useData } from './DataContext';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import { FaDiceD20 } from "react-icons/fa6";
import '../styles/RollLog.css';

const RollLog = () => {
  const [input, setInput] = useState('');
  const [rollEntries, setRollEntries] = useState([]);
  const endOfRollsRef = useRef(null);

  const { playableCharacters, selectedChar } = useData();
  const characterName = playableCharacters && selectedChar ? 
    playableCharacters[playableCharacters.findIndex((x) => (x.id == selectedChar))].name
    :
    null;

  const socket = useSocket();

  useEffect(() => {
    if (socket == null) return;

    const handleEvent = (data) => {
      setRollEntries(prevEntries => [...prevEntries, { id: Date.now(), ...data }]);
    };

    socket.on('roll_update', handleEvent);

    // Cleanup on component unmount
    return () => socket.off('roll_update', handleEvent);
  }, [socket]);

  useEffect(() => {
    endOfRollsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [rollEntries]);
  
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // Send input to the backend
    const response = await fetch('/roll', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt: input, user: characterName }),
    });
    const data = await response.json();

    // if response.status !== 200...

    // Reset input field
    setInput('');
  };

  return (
    <div className="roll-log-container">
      <div className="roll-log">
          {rollEntries.map((entry, i) => (
            <RollEntry key={entry.id} idx={i} roll={entry} />
          ))}
          <div ref={endOfRollsRef} />
      </div>
      <div>
        <form className="roll-form" onSubmit={handleSubmit}>
          <Input
            type="text"
            sx={{
              "width": "100%"
            }}
            id="roll-input"
            value={input}
            onChange={handleInputChange}
            autoComplete={"off"}
            placeholder="Roll..."
          />
          <Button
            sx={{
              "background": "linear-gradient(to top right, #2d1733, #d661f9)",
              "margin-left": "5px"
            }}
            className="roll-btn"
            type="submit"
          >
            <FaDiceD20 className="btn-content"/>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RollLog;