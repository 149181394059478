import React from 'react';
import NamePlate from './NamePlate';
import { useData } from './DataContext';
import '../styles/CharacterPlates.css';

const CharacterPlates = () => {
    const charPlayers = useData()['playableCharacters'];
    const selectedChar = useData().selectedChar;

    function floatElementToTop(arr, condition) {
        // Create a copy of the array to avoid modifying the original array
        const newArr = [...arr];
        // Find the index of the element that meets the condition
        const index = newArr.findIndex(condition);
        // If the element is found, move it to the beginning of the array
        if (index !== -1) {
            const [element] = newArr.splice(index, 1); // Remove the element from its current position
            newArr.unshift(element); // Insert the element at the beginning of the array
        }
        return newArr;
    }

    return (
        <div className="character-plates">
            { charPlayers ? floatElementToTop(charPlayers, (x) => x.id == selectedChar).map(character => (
                <NamePlate 
                    key={character.id}
                    level={character.level}
                    charClass={character.class}
                    race={character.race}
                    name={character.name} 
                    profilePic={character.profileImage} 
                    currentHealth={character.currentHealth} 
                    maxHealth={character.maxHealth}
                    selected={character.id==selectedChar}
                />
            )) : ''}
        </div>
    );
};

export default CharacterPlates;