import React, { useState, useRef, useEffect } from 'react';
import RollLog from './RollLog';
import CharacterPlates from './CharacterPlates';
import '../styles/SideBar.css';

const SideBar = () => {
  return (
    <div className="sidebar">
      <CharacterPlates />
      <RollLog />
    </div>
  );
}

export default SideBar;