import React, { useState, useRef, useEffect } from 'react';
import { useData } from './DataContext';
import { useSocket } from './SocketContext';
import Message from './Message';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { FaPaperPlane } from "react-icons/fa6";
import '../styles/Chat.css';
import AudioControl from './AudioControl';

const Chat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState({});
  const [audioContext, setAudioContext] = useState(null);
  const messagesEndRef = useRef(null);

  const { selectedChar } = useData();
  
  const socket = useSocket();

  useEffect(() => {
    // Create AudioContext once for the lifetime of the component
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);

    return () => {
        if (context) {
            context.close(); // Properly close the context when component unmounts
        }
    };
  }, []);

  useEffect(() => {
    if (socket == null || !audioContext) return;

    const handleTextEvent = (data) => {
      setMessages((currentMessages) => {
        if (data.fragment && data.id in currentMessages) {
          data.text = currentMessages[data.id].text.concat(data.text);
        }
        return {...currentMessages, [data.id]: data};
      });
    };

    const playAudio = (arrayBuffer) => {
      audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0);
      }, (e) => {
        console.error("Error decoding audio data", e);
      });
    };

    const handleAudioEvent = (audio_message) => {
      playAudio(audio_message.audio);
    };

    socket.on('message_update', handleTextEvent);
    socket.on('narration', handleAudioEvent);

    // Cleanup on component unmount
    return () => {
      socket.off('message_update', handleTextEvent);
      socket.off('narration', handleAudioEvent);
    };
  }, [socket]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // Reset input field
    setInput('');

    // Send input to the backend
    const response = await fetch('/generate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: Date.now(), prompt: input, sender: selectedChar }),
    });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div id="chat-container">
      <div id="messages-container">
        {Object.keys(messages).length ? Object.keys(messages).map((key) => (
          <Message key={messages[key].id} human={messages[key].human} sender={messages[key].user} text={messages[key].text} />
        )) : '' }
        <div ref={messagesEndRef} />
      </div>
      <AudioControl />
      <div>
        <form id="chat-form" onSubmit={handleSubmit}>
          <Input
            type="text"
            id="chat-input"
            value={input}
            sx={{width: "100%"}}
            onChange={handleInputChange}
            autoComplete={"off"}
            placeholder="Type your message here..."
          />
          <Button
            sx={{
              "background": "linear-gradient(to top right, #2d1733, #d661f9)",
              "margin-left": "5px"
            }}
            className="submit-btn"
            type="submit"
          >
            <FaPaperPlane className="btn-content"/>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Chat;