import React from 'react';
import Chat from './Chat';
import SideBar from './SideBar';
import CharacterSelectionModal from './CharacterSelectionModal';
import CharacterForm from './CharacterForm';
import Modal from './Modal';
import { useData } from './DataContext';
import '../styles/App.css';

const AppContent = () => {
  const { playableCharacters, selectedChar, charEditMode, setCharEditMode } = useData();

  return (
    <>
      <Modal isOpen={!selectedChar} children={<CharacterSelectionModal />} />
      {charEditMode && <Modal
        isOpen={charEditMode}
        children={
          <CharacterForm
            isEditMode={true}
            characterToEdit={playableCharacters[playableCharacters.findIndex((x) => x.id == selectedChar)]}
            cancel={() => setCharEditMode(false)}
            />} />}
      <Chat />
      <SideBar />
    </>
  );
};

function App() {
  return (
      <header className="App-header">
        <div className="app-container">
          <AppContent />
        </div>
      </header>
  );
}

export default App;
